import styles from './NavBar.module.css'
import {ReactComponent as Logo} from '../../assets/nav_icons/logo.svg'
import {ReactComponent as RSS} from '../../assets/nav_icons/rss.svg'
import {ReactComponent as DarkMode} from '../../assets/nav_icons/darkmode.svg'
import {ReactComponent as NewFeature} from '../../assets/nav_icons/new_feature.svg'
import {ReactComponent as Support} from '../../assets/nav_icons/support.svg'
import {ReactComponent as Profile} from '../../assets/nav_icons/profile.svg'


export default function NavBar() {


    return (
        <nav className={styles.navBar}>
            <Logo className={styles.icon}></Logo>

            {/* nav items*/}
            <div className={styles.navItems}>
                <RSS className={styles.icon}></RSS>
                <DarkMode className={styles.icon}></DarkMode>
                <NewFeature className={styles.icon}></NewFeature>
                <Support className={styles.icon}></Support>
            </div>

            <Profile className={styles.icon}></Profile>
        </nav>
    )
}