import styles from "./ContentListItem.module.css"

export default function ContentListItem(props) {
    return (
        <div className={styles.item}>
            <div className={styles.icon}>{<props.icon/>}</div>
            

            <div className={styles.textArea}>
                <a href={props.link}><h4 className={styles.title}>{props.title}</h4></a>
                <p className={styles.description}>{props.description.substring(0, 40)}</p>
            </div>



        </div>
    )
}