import styles from './Menu.module.css'
import {ReactComponent as Logo} from './assets/logo.svg'
import {ReactComponent as Bookmark} from './assets/bookmark.svg'
import {ReactComponent as Zhihu} from './assets/zhihu.svg'

import ListItemSelector from './components/ListItemSelector'

import {RSSSource} from './assets/RSSSource.js'



export default function Menu(props) {
    const RSSList = RSSSource.map((item) => {
        return (
            <ListItemSelector
                key={item.name}
                icon={Zhihu}
                title={item.name}
                source={item.source}
                changeRSS={props.changeRSS}
                item = {item}
                size='20px'
                type='heading'
            ></ListItemSelector>
        )
    })

    return (
        <main className={styles.main}>
            {/* header*/}
            <section className={styles.section}>
                <ListItemSelector
                    icon={Logo}
                    title='Today'
                    size='20px'
                    type='heading'
                ></ListItemSelector>

                <ListItemSelector
                    icon={Bookmark}
                    title='Read later'
                    size='20px'
                    type='heading'
                ></ListItemSelector>
            </section>


            {/* Main menu */}
            <section>
                <h3 className={styles.heading}>FEEDS</h3>

                <div>
                    {RSSList}

                </div>
            </section>
        </main>
    )
}
