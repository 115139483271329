import styles from './ListItemSelector.module.css'


/*
- icon
- title
- size
- source
- type: heading => bold text
                => normal text
*/

export default function ListItemSelector(props) {

    console.log(props.item)
    return (
        <div className={styles.listItem} tabIndex='0' onClick={() => props.changeRSS(props.item)}>
            {<props.icon 
                width={props.size}
                height={props.size}
            />}
            <span
                className={styles.title}
                style={props.type === 'heading' ? {fontWeight: 700} : {fontWeight: 'normal'}}
            >
                {props.title}
            </span>
        </div>
    )
}