import styles from './App.module.css';

import NavBar from './components/Header/Navbar';
import Menu from './Menu';
import Contents from './Contents'
import { useState } from 'react';

function App() {
  const [currentRSS, setCurrentRSS] = useState('0')
  
  function changeRSS(targetURL) {
    setCurrentRSS(targetURL)
  }



  return (
    <div className={styles.App}>
      <NavBar></NavBar>
      <Menu changeRSS = {changeRSS}></Menu>
      <Contents currentRSS= {currentRSS}></Contents>
    </div>
  );
}

export default App;
