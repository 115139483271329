import styles from './Contents.module.css'

import {ReactComponent as IconPlaceholder} from './assets/icon_placeholder.svg'

import ContentListItem from './components/ContentListItem'

import FetchContent from './components/FetchContent'
import { useState, useEffect } from 'react'

export default function Contents(props) {
    const [listHTML, setListHTML] = useState()

    console.log('current rss', props.currentRSS)

    const contentConstruct = async() => {
        const serverResponse = await FetchContent('https://rsshub.che-zhu.me/zhihu/daily')
        console.log(serverResponse)

        const responseHTML = serverResponse.entries.map((entry) => {
            return (
                <ContentListItem
                    key={entry.title}
                    icon={IconPlaceholder}
                    title={entry.title}
                    description={entry.description}
                    link={entry.link}
                ></ContentListItem>
            )
        })

        setListHTML(responseHTML)


    }

    // prevent this page from re-render multiple times
    useEffect(() => {
        contentConstruct()
    }, [])
    


    return (
        <section className={styles.contents}>
            <h2 className={styles.heading}>A Placeholder Title {props.currentRSS.name}</h2>

            <div>
                {listHTML}
            </div>

        </section>
    )
}